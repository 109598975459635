<template>
  <a-modal :visible="true" title="绑定职位" :maskClosable="false" @ok="submit" @cancel="cancel" :width="930"
    :confirm-loading="confirmLoading" :closable="false">
    <a-form-model ref="ruleForm" :model="form" :rules="rules"
      :labelCol="{ style: 'width: 80px; text-align: left;vertical-align: top; ' }"
      :wrapperCol="{ style: 'width: 300px; display: inline-block;' }">
      <a-form-model-item label="所属模块：" style="margin-top: 20px" prop="moduleId">
        <a-select :getPopupContainer="(triggerNode) => triggerNode.parentNode" v-model="form.moduleId"
          placeholder="请选择招聘模块">
          <a-select-option v-for="item in moduleList" :value="item.moduleId" :key="item.moduleId">
            {{ item.moduleName }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
    </a-form-model>
    <!-- 待添加职位 -->
    <p>待添加职位</p>
    <a-button type="primary" @click="batchDel" style="margin-top: 3px" :loading="searchBtnLoading">
      批量删除
    </a-button>
    <a-table :row-selection="{
      selectedRowKeys: delselectedRowKeys,
      onChange: delselectedPosition,
      onSelect: delselectedPositionOne,
      onSelectAll: delselectedPositionAll,
      type: 'checkbox',
    }" :columns="table.columns" :data-source="form.positionInfoList"
      :pagination="pagination.total > 0 ? pagination : false" @change="pageChange2" :rowKey="(record, index) => record.recruitId" class="my-20"
      bordered style="margin-top: 20px" :scroll="{ y: 300 }">
      <span slot="action" slot-scope="text, record" class="action">
        <a-button type="link" @click="toDel(record)"  style="color: #f5222d"  class="btn"> 删除 </a-button>
      </span>
    </a-table>

    <a-form layout="inline">
      <a-form-item label="职位名称：">
        <a-input v-model.trim="searchForm.jobName" placeholder="请输入职位名称以搜索" class="inputClass" />
      </a-form-item>
      <a-form-item label="企业名称：">
        <a-input v-model.trim="searchForm.enterpriseName" placeholder="请输入企业名称以搜索" class="inputClass" />
      </a-form-item>
      <a-button type="primary" @click="search" style="margin-top: 3px" :loading="searchBtnLoading">
        查询
      </a-button>
    </a-form>
    <a-button type="primary" @click="batchAdd" style="margin-top: 3px" :loading="searchBtnLoading">
      批量添加
    </a-button>
    <a-table :row-selection="rowSelection" :columns="table.columns" :data-source="table.dataInfo"
      :pagination="table.pagination.total > 0 ? table.pagination : false" @change="pageChange"
      :rowKey="(record, index) => record.recruitId" class="my-20" bordered style="margin-top: 20px" :scroll="{ y: 300 }">
      <span slot="action" slot-scope="text, record" class="action">
        <a-button type="link" @click="toAdd(record)" class="btn"> 添加 </a-button>
      </span>
    </a-table>
  </a-modal>
</template>

<script>
import * as api from '@/api/socialRecruitingServicesManagement';
import { SUCCESS_CODE } from '@/config';
export default {
  name: 'AddModulePosition',
  props: {
    moduleList: {
      type: Array,
      default: [],
    },
  },
  computed: {
    rowSelection() {
      console.log('======');
      return {
        selectedRowKeys: this.table.selectedRowKeys,
        type: 'checkbox',
        onChange: this.selectedPosition,
        onSelect: this.selectedPositionOne,
        onSelectAll: this.selectedPositionAll,
        getCheckboxProps: record => ({
          props: {
            disabled: record.disabled
            // Column configuration not to be checked
          },
        }),
      };
    },
  },
  mounted() {
    // 默认下拉
    // if(this.moduleList && this.moduleList.length>0) {
    //   this.form.moduleId = this.moduleList[0].moduleId;
    // }
  },
  watch: {
    listSelectedPosition: {
      handler(newVal) {
        if (newVal) {
          this.form.recruitId = newVal;
        }
      },
      immediate: true,
    },
  },
  data() {
    return {
      confirmLoading: false, // 确定按钮loading
      searchForm: {
        jobName: '',
        enterpriseName: '',
        moduleId: '',
      },
      table: {
        dataInfo: [],
        selectedRowKeys: [],
        columns: [
          {
            title: '职位名称',
            dataIndex: 'jobName',
            key: 'jobName',
            width: 100,
            scopedSlots: { customRender: 'jobName' },
          },
          {
            title: '企业名称',
            dataIndex: 'enterpriseName',
            key: 'enterpriseName',
            width: 150,
          },
          {
            title: '就职园区',
            dataIndex: 'projectName',
            key: 'projectName',
            width: 200,
            scopedSlots: { customRender: 'projectName' },
          },
          {
            title: "操作",
            key: "action",
            width: 100,
            scopedSlots: { customRender: "action" },
          },
        ],
        //分页数据
        pagination: {
          pageSizeOptions: ["10", "20", "30", "50", "100"],
          showSizeChanger: true,
          current: 1,
          pageSize: 10,
          total: 0,
          showQuickJumper: true,
          showTotal: (total) => {
            return `共 ${total} 条`;
          },
        },

      },
      //分页数据
      pagination: {
        pageSizeOptions: ["10", "20", "30", "50", "100"],
        showSizeChanger: true,
        current: 1,
        pageSize: 10,
        total: 0,
        showQuickJumper: true,
        showTotal: (total) => {
          return `共 ${total} 条`;
        },
      },
      searchBtnLoading: false, // 搜索按钮loading
      form: {
        moduleId: '', // 模块ID
        positionInfoList: [], //职位集合
      },
      rules: {
        moduleId: [{ required: true, message: '请选择招聘模块', trigger: 'blur' }],
      },
      // 批量添加
      batchAddList: [],
      delselectedRowKeys: [],
      batchDelList: []
    };
  },
  methods: {
    // 查询
    search() {
      if (!this.isSelectedPosition()) return false;
      this.table.pagination.current = 1;
      this.table.pagination.pageSize = 10;
      this.table.selectedRowKeys = []
      this.batchAddList = []
      this.searchPositionInfo();
    },
    // 选中职位
    selectedPosition(items) {
      this.table.selectedRowKeys = items;
      this.form.recruitId = items[0];
    },
    // 选中删除职位
    delselectedPosition(items) {
      console.log(items, 'itren===>');
      this.delselectedRowKeys = items;
    },
    delselectedPositionOne(record, selected) {
      console.log(record, selected);
      // 若选中，直接push
      if (selected) {
        this.batchDelList.push({
          recruitId: record.recruitId,
          enterpriseId: record.enterpriseId,
          enterpriseName: record.enterpriseName,
          projectName: record.projectName,
          jobName: record.jobName,
        })
      } else {
        // 若取消选中，则删除数据
        this.batchDelList = this.batchDelList.filter(item => {
          return item.recruitId !== record.recruitId
        });
      }
      console.log(this.batchDelList);
    },
    delselectedPositionAll(selected, selectedRows, changeRows) {
      console.log(selected, selectedRows, changeRows);
      if (selected) {
        this.batchDelList.push(...changeRows)
      } else {
        // 若取消选中，则删除数据
        for (let index = 0; index < this.batchDelList.length; index++) {
          const item = this.batchDelList[index];
          for (let j = 0; j < changeRows.length; j++) {
            const element = changeRows[j];
            if (element.recruitId === item.recruitId) {
              console.log(element.recruitId);
              this.batchDelList.splice(index, 1)
              index--
              break
            }
          }
        }
      }

    },
    selectedPositionOne(record, selected) {
      console.log(record, selected);
      // 若选中，直接push
      if (selected) {
        this.batchAddList.push({
          recruitId: record.recruitId,
          enterpriseId: record.enterpriseId,
          enterpriseName: record.enterpriseName,
          projectName: record.projectName,
          jobName: record.jobName,
        })
      } else {
        // 若取消选中，则删除数据
        this.batchAddList = this.batchAddList.filter(item => {
          return item.recruitId !== record.recruitId
        });
      }
      console.log(this.batchAddList);
    },
    selectedPositionAll(selected, selectedRows, changeRows) {
      console.log(selected, selectedRows, changeRows);
      // 若选中，直接push
      if (selected) {
        this.batchAddList.push(...changeRows)
        console.log(this.form.positionInfoList);
      } else {
        // 若取消选中，则删除数据
        for (let index = 0; index < this.batchAddList.length; index++) {
          const item = this.batchAddList[index];
          console.log(selectedRows);
          for (let j = 0; j < changeRows.length; j++) {
            const element = changeRows[j];
            if (element.recruitId === item.recruitId) {
              console.log(element.recruitId);
              this.batchAddList.splice(index, 1)
              index--
              break
            }
          }
        }
        console.log(this.form.positionInfoList, '====取消');
      }
    },
    toAdd(record) {
      if (!this.form.positionInfoList.some(item => {
        return item.recruitId === record.recruitId
      })) {
        this.form.positionInfoList.push({
          recruitId: record.recruitId,
          enterpriseId: record.enterpriseId,
          enterpriseName: record.enterpriseName,
          projectName: record.projectName,
          jobName: record.jobName,
        });
      }
      console.log(this.table.dataInfo);
    },
    toDel(record) {
      for (let index = 0; index < this.form.positionInfoList.length; index++) {
        let item = this.form.positionInfoList[index]
        if (item.recruitId === record.recruitId) {
          this.form.positionInfoList.splice(index, 1)
          this.delselectedRowKeys.splice(index, 1)
        }
      }
      console.log(this.form.positionInfoList);
    },
    batchAdd() {
      if (this.form.positionInfoList.length === 0) {
        this.form.positionInfoList = [...this.batchAddList]
      } else {
        let newary = []
        newary = [...this.form.positionInfoList, ...this.batchAddList]
        this.delrepeat(newary)
        this.form.positionInfoList = newary
      }
      this.pagination.total = this.form.positionInfoList.length

    },
    // 批量删除
    batchDel() {
      for (let index = 0; index < this.form.positionInfoList.length; index++) {
        const item = this.form.positionInfoList[index];
        for (let j = 0; j < this.batchDelList.length; j++) {
          const element = this.batchDelList[j];
          console.log('++++++++++');
          if (element.recruitId === item.recruitId) {
            console.log(element.recruitId);
            this.form.positionInfoList.splice(index, 1)
            index--
            break
          }
        }
      }
    },
    delrepeat(ary) {
      for (let index = 0; index < ary.length; index++) {
        const item = ary[index];
        for (let j = index + 1; j < ary.length; j++) {
          const element = ary[j];
          console.log('++++++++++');
          if (element.recruitId === item.recruitId) {
            console.log(element.recruitId);
            ary.splice(index, 1)
            index--
            break
          }
        }
      }
    },
    // 提交
    submit() {
      if (!this.isSelectedPosition()) return false;
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.addPosition();
        }
      });
    },
    // 取消
    cancel() {
      this.$emit('update:show-add-module-position', false);
    },
    /**网络请求 */
    async searchPositionInfo() {
      try {
        this.searchBtnLoading = true;
        this.searchForm.moduleId = this.form.moduleId;
        const data = {
          page: this.table.pagination.current,
          size: this.table.pagination.pageSize,
          ...this.searchForm
        };
        const result = await api.getSpecialModulePositionList(data);
        this.searchBtnLoading = false;
        if (result.code === SUCCESS_CODE) {
          this.table.dataInfo = result.data.list;
          this.table.pagination.total = result.data.total;
        }
      } catch (error) {
        this.searchBtnLoading = false;
        console.log(error);
      }
    },
    // 切换分页
    pageChange(option) {
      this.table.pagination.current = option.current;
      this.table.pagination.pageSize = option.pageSize;
      this.searchPositionInfo();
    },
    // 切换分页
    pageChange2(option) {
      this.pagination.current = option.current;
      this.pagination.pageSize = option.pageSize;
    },
    async addPosition() {
      try {
        this.confirmLoading = true;
        const result = await api.addSpecialPosition({ ...this.form });
        this.confirmLoading = false;
        if (result.code === SUCCESS_CODE) {
          this.$message.success(result.msg);
          this.$emit('update:show-add-module-position', false);
          this.$emit('refreshPositionList');
        } else {
          this.$message.error(result.msg);
        }
      } catch (error) {
        console.log(error);
        this.$message.error('绑定职位失败');
        this.confirmLoading = false;
      }
    },
    // 校验是否选择模块
    isSelectedPosition() {
      if (!this.form.moduleId) {
        this.$message.warning('请选择要绑定的模块');
        return false;
      }
      return true;
    },
  },
};
</script>

<style lang="scss" scoped></style>
